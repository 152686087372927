.containerMenu1{
    text-align: center;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    height: 50%;
    width: 70%;
    padding: 40px;
    background-color: white;
 

}
.containerMenu2{
  text-align: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -15%);
  height: 50%;
    width: 70%;
  padding: 40px;
  background-color: white;
  opacity: 0.9;

}
.cerrar{
    text-align: right;
    z-index: -3;
}

.footer{
    margin-top: 1rem;
    padding: 1rem;
    background-color: rgb(255, 255, 255);
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  h1{
    text-align: left;
  }

  input{
    cursor: pointer;
  }

  label{
    cursor: pointer;
  }
  
  .formGroupRadios{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .formGroupRadios > .form-group{
    padding-left: 15px;
  }
  
  .form-check-label{
    padding-left: 3px;
  }
  .align-left {
    text-align: left;
  }
  .sweet{
    text-align: left;
  }